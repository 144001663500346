import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureService {
  private readonly featuresSubject = new BehaviorSubject<string[]>([]);
  public enabledFeatures = this.featuresSubject.asObservable();

  initialize(): void {
    posthog.onFeatureFlags((featureFlags) => {
      const result = [];
      for (const flag of featureFlags) {
        try {
          if (posthog.isFeatureEnabled(flag)) {
            result.push(flag);
          }
        } catch {
          // Left empty intentionally
        }
      }
      this.featuresSubject.next(result);
    });
  }
}
